import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "relative flex justify-between w-11/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icons = _resolveComponent("Icons")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createBlock("div", {
        key: `${index}`,
        class: "flex flex-col justify-center whitespace-nowrap relative w-4/12"
      }, [
        _createVNode("div", {
          onClick: ($event: any) => (_ctx.switchStep(index)),
          class: ["rounded-full z-10 cursor-pointer border font-medium text-white p-3 flex justify-center items-center mb-3", 
          `${
            index === 0
              ? 'self-start'
              : index === _ctx.steps.length - 1
              ? 'self-end'
              : 'self-center'
          } ${
            _ctx.progress >= index
              ? 'bg-red-lp-300 border-red-lp-300 text-white'
              : index === _ctx.progress + 1
              ? 'border-red-lp-300 bg-white text-gray-lp-300'
              : 'bg-gray-lp-1000 border-white text-gray-lp-300'
          }`
        ]
        }, [
          (_ctx.progress >= index)
            ? (_openBlock(), _createBlock("div", {
                key: 0,
                style: _ctx.styleIconCheck(index),
                class: "absolute bg-white w-4 rounded-full flex justify-center items-center"
              }, [
                _createVNode(_component_Icons, {
                  class: "w-4 text-green-lp-100",
                  name: "check-circle"
                })
              ], 4))
            : _createCommentVNode("", true),
          _createVNode(_component_Icons, {
            class: "w-4",
            name: step.icon
          }, null, 8, ["name"])
        ], 10, ["onClick"]),
        _createVNode("div", {
          class: 
          `${
            index === 0
              ? 'self-start'
              : index === _ctx.steps.length - 1
              ? 'self-end'
              : 'self-center'
          } ${_ctx.progress >= index ? 'font-semibold' : 'font-normal'}`
        
        }, _toDisplayString(step.label), 3),
        (index < _ctx.steps.length - 1)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: ["absolute flex justify-center items-center z-0", 
          `${
            _ctx.progress >= index
              ? 'bg-red-lp-300'
              : 'border-t border-dashed border-gray-lp-1300'
          }`
        ],
              style: [{"top":"1.25rem","height":"0.2rem"}, _ctx.styleWidthProgress(index)]
            }, null, 6))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}