import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, vShow as _vShow, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hidden" }
const _hoisted_2 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Print = _resolveComponent("Print")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.cargoListAvailable)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          customMessage: "Oops.. Belum ada data yang dapat ditampilkan"
        }))
      : (_openBlock(), _createBlock(_component_TableV2, {
          key: 1,
          columns: _ctx.columns,
          data: _ctx.cargoList,
          loading: _ctx.isLoading,
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
          totalColumnFreeze: 1,
          paginationStyle: "v3",
          isRightFreeze: "",
          onRequest: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchCargoList('retry'))),
          errorCause: _ctx.errorCause,
          isDetailAble: _ctx.ableToViewDetail,
          isTabList: true,
          onClick: _ctx.onClickRow,
          groupAction: true,
          groupOptions: _ctx.actionGroupOptions
        }, null, 8, ["columns", "data", "loading", "pagination", "errorCause", "isDetailAble", "onClick", "groupOptions"])),
    _withDirectives(_createVNode("div", _hoisted_1, null, 512), [
      [_directive_click_outside, () => (_ctx.chevronUp = _ctx.chevronUp.map(() => false))]
    ]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: () => _ctx.handlerModalCancelBooking(false),
      onClose: () => _ctx.cancelCargo(),
      title: "Apakah anda yakin ingin membatalkan?",
      message: "Jika memilih \"Ya\" sistem akan membatalkan proses booking",
      image: "badge-confirmation-general",
      textSuccess: "Tidak",
      textCancel: "Ya",
      class: "px-8"
    }, null, 8, ["onSubmit", "onClose"]), [
      [_vShow, _ctx.showModalCancelBooking]
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Print, { ref: "print" }, null, 512)
    ])
  ], 64))
}