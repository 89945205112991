import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hidden" }
const _hoisted_2 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Print = _resolveComponent("Print")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.cargoListAvailable)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          customMessage: _ctx.$t('Oops.. Belum ada data yang dapat ditampilkan')
        }, null, 8, ["customMessage"]))
      : (_openBlock(), _createBlock(_component_TableV2, {
          key: 1,
          columns: _ctx.columns,
          data: _ctx.cargoList,
          loading: _ctx.isLoading,
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
          totalColumnFreeze: 1,
          paginationStyle: "v3",
          isRightFreeze: "",
          onRequest: _ctx.fetchCargoList,
          errorCause: _ctx.errorCause,
          isDetailAble: _ctx.ableToViewDetail,
          isTabList: true,
          onClick: _ctx.onClickRow,
          groupAction: true,
          groupOptions: _ctx.actionGroupOptions
        }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "isDetailAble", "onClick", "groupOptions"])),
    _withDirectives(_createVNode("div", _hoisted_1, null, 512), [
      [_directive_click_outside, () => (_ctx.chevronUp = _ctx.chevronUp.map(() => false))]
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Print, { ref: "print" }, null, 512)
    ]),
    (_ctx.isErrorPrint && !_ctx.isLoadingPrint)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 2,
          errorType: _ctx.errorCause,
          onCancel: _ctx.closeModalErrorPrintList,
          onTryAgain: () => _ctx.refs.print.printManifest(_ctx.cargoNumber),
          modal: ""
        }, null, 8, ["errorType", "onCancel", "onTryAgain"]))
      : _createCommentVNode("", true)
  ], 64))
}