
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import {
  checkRolePermission,
  firstCapitalize,
  formatTimeNumber
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { CARGO } from "@/app/infrastructures/misc/RolePermission";
import { CargoController } from "../../controllers/CargoController";
import debounce from "lodash/debounce";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import router from "../../router";
import Print from "./modules/print.vue";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { MainAppController } from "../../controllers/MainAppController";
import { CargoData, CargoInputData } from "@/domain/entities/Cargo";
import { OptionsClass } from "@/domain/entities/MainApp";
import { LocationController } from "../../controllers/LocationController";

@Options({
  components: {
    EmptyState,
    Print
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
    ableToCreate() {
      return checkRolePermission(CARGO.CREATE);
    },
    ableToViewDetail() {
      return checkRolePermission(CARGO.DETAIL);
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class Cargo extends Vue {
  // Tabs
  tabs = [
    { name: "booking-success", title: "Berhasil Booking" },
    { name: "booking-in-progress", title: "Dalam Proses Booking" },
    { name: "booking-canceled", title: "Dibatalkan/Gagal Booking" }
  ];

  changeTabs(value: string) {
    (this.$refs.advancedFilter as any)?.resetfilter(false);
    this.$router.push({
      name: value
    });
  }

  get accountIsForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }

  mounted() {
    CargoController.setFirstPage();
    CargoController.setPlaneBooking(false);
    this.refs = this.$refs;
  }
  unmounted() {
    CargoController.setFirstPage();
    CargoController.onReset();
  }

  fetchCargoList() {
    CargoController.fetchCargoList({
      type: CargoController.cargoStatus ? "retry" : ""
    });
  }

  // filter date
  get periodeStart() {
    return CargoController.periodeStart;
  }

  get periodeEnd() {
    return CargoController.periodeEnd;
  }

  setPeriode(value: Array<any>) {
    CargoController.setPeriodeStart(value[0]);
    CargoController.setPeriodeEnd(value[1]);
    if (value[0] && value[1]) {
      CargoController.setFirstPage();
      this.fetchCargoList();
    }
  }

  showModalSTTPaid = false;
  handlerModalSTTPaid(val: boolean) {
    if (this.accountIsForeign) {
      dataLayer("booking_cargo_click", {}, [
        "userId",
        "username",
        "userType",
        "timestamp"
      ]);
      CargoController.resetCargoInputForm(new CargoInputData()); //add inital value cargo-input-data on localstorage for booking cargo
      router.push("/cargo/create");
      this.getTabs();
    }
    this.showModalSTTPaid = val;
  }

  // goToCreate
  goToCreate() {
    dataLayer("booking_cargo_click", {}, [
      "userId",
      "username",
      "userType",
      "timestamp"
    ]);
    CargoController.resetCargoInputForm(new CargoInputData()); //add inital value cargo-input-data on localstorage for booking cargo
    router.push("/cargo/create");
    this.handlerModalSTTPaid(false);
    this.getTabs();
  }

  get originCity(): OptionsClass {
    const detailDistrict = LocationController.districtDetail;
    return new OptionsClass({
      name: `${detailDistrict.cityCode} - ${firstCapitalize(
        detailDistrict.cityName.toLowerCase()
      )}`,
      code: detailDistrict.cityCode
    });
  }

  async getTabs() {
    await CargoController.getCargoOptions({
      origin: {
        code: this.originCity.code || "",
        name: this.originCity.name
      },
      destination: {
        code: "",
        name: ""
      },
      isPlaneManual: false
    });
  }

  refs: any = "";

  get dataProfile() {
    return AccountController.accountData;
  }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  //Filter Search
  search = "";
  onSearch(value: string) {
    CargoController.searchAction(value);
  }
  get searchValue() {
    return CargoController.search;
  }
  clearSearch() {
    CargoController.searchAction("");
    this.search = "";
  }

  // advance filter
  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    CargoController.setAdvanceFilterData(event);
    CargoController.setFirstPage();
    this.fetchCargoList();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce((isFetch: boolean) => {
    CargoController.setAdvanceFilterData({
      type: { name: "", value: "" },
      cityOriginCode: "",
      cityDestinationCode: "",
      airport: ""
    });
    CargoController.setFirstPage();
    if (isFetch) this.fetchCargoList();
  }, 500);

  get advancedFilterData() {
    return CargoController.advanceFilterData;
  }
  get typeList() {
    return [
      {
        name: this.$t("Pesawat"),
        value: "plane"
      },
      {
        name: this.$t("Truk"),
        value: "truck"
      },
      {
        name: this.$t("Kereta"),
        value: "train"
      },
      {
        name: this.$t("Kapal"),
        value: "ship"
      }
    ];
  }

  get isCargoListDownload() {
    return (CargoController.cargoStatus !== "process-immediate,process,process-immediate-tracking,process-webhook"
    );
  }

  get cargoListStatus() {
    return CargoController.cargoStatus;
  }

  get cargoListSuccessMapping() {
    const res: any = [];
    CargoController.cargoData.data.forEach((item: CargoData) => {
      const departure = item.cargoType === 'plane' ? item.departureTime : item.departureDate
      const arrival = item.cargoType === 'plane' ? item.arrivalTime : item.arrivalDate
      res.push({
        "No Kargo": item.cargoNumber,
        "Tipe Kargo":
          this.typeList.find(type => type.value === item.cargoType)?.name || "",
        "Kota Asal": `${item.originCityCode} - ${item.originCityName}`,
        "Kota Tujuan": `${item.destinationCityCode} - ${item.destinationCityName}`,
        "Kode Bandara Tujuan":
          item.cargoType === "plane"
            ? `${item.airportDestinationCityCode} - ${item.airportDestinationCityName}`
            : "-",
        "No seri kendaraan/No penerbangan": item.vehiclePoliceNumber,
        "Tanggal keberangkatan": this.$moment(item.departureDate).format(
          "DD MMMM YYYY"
        ),
        "Jam keberangkatan": departure
          ? this.$moment(departure)
              .utcOffset(departure)
              .format("HH:mm")
          : "-",
        "Tanggal kedatangan": this.$moment(item.arrivalDate).format(
          "DD MMMM YYYY"
        ),
        "Jam kedatangan": arrival
          ? this.$moment(arrival)
              .utcOffset(arrival)
              .format("HH:mm")
          : "-",
        "Total STT": item.totalStt,
        "Total Koli": item.totalPieces,
        "Total berat kotor": item.totalActualCargoGrossWeight,
        "Tanggal dibuat": this.$moment(item.createdAt).format(
          "DD/MM/YYYY HH:mm"
        ),
        "Dibuat oleh": item.createdBy,
        "Tanggal diubah": this.$moment(item.updatedAt).format(
          "DD/MM/YYYY HH:mm"
        ),
        "Diubah oleh": item.updatedBy
      });
    });
    return res;
  }

  get cargoListFailedMapping() {
    const res: any = [];
    CargoController.retryCargo.data.forEach((item: any, index: number) => {
      res.push({
        No: index + 1,
        TipeKargo: item.cargoType,
        StatusKargo: "Gagal",
        NoKargo: item.cargoNumber,
        KotaAsal: item.originCityCode,
        KotaTujuan: item.destinationCityCode,
        TotalSTT: item.totalStt,
        TotalKoli: item.totalPieces,
        TotalBeratAktual: item.totalActualCargoGrossWeight,
        NoSeriKendaraan: item.vehiclePoliceNumber,
        ETD: formatTimeNumber(item.departureDate).slice(0, 5),
        ETA: formatTimeNumber(item.arrivalDate).slice(0, 5),
        UserCreated: item.createdBy,
        TanggalDibuat: item.createdAt,
        TanggalDiubah: item.updatedAt
      });
    });
    return res;
  }

  async download(status: any) {
    MainAppController.showLoading();
    const XLSX = await import("xlsx");
    const data = XLSX.utils.json_to_sheet(
      status ? this.cargoListFailedMapping : this.cargoListSuccessMapping
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      data,
      status ? "cargo_list_failed" : "cargo_list_success"
    );
    XLSX.writeFile(
      wb,
      status ? "cargo-list-failed.xlsx" : "cargo-list-success.xlsx"
    );
    MainAppController.closeLoading();
  }
}
