
import { Vue, Options } from "vue-class-component";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";

@Options({
  components: {}
})
export default class TheStepperBookingCargo extends Vue {
  steps = [
    {
      icon: "plus-circle-outline",
      label: "Tambah STT"
    },
    {
      icon: "cargo-configuration",
      label: "Cari Kargo"
    },
    {
      icon: "custom-process",
      label: "Proses Kargo"
    }
  ];

  styleIconCheck(index: number) {
    if (index === 0) {
      return {
        top: "1.75rem",
        left: "1.75rem"
      };
    }
    if (index === this.steps.length - 1) {
      return {
        top: "1.75rem",
        right: "-.25rem"
      };
    }
    return {
      top: "1.75rem",
      left: "52%"
    };
  }
  styleWidthProgress(index: number) {
    if (index === 0) {
      return {
        width: "150%",
        left: "0"
      };
    }
    return {
      width: "150%",
      left: "50%"
    };
  }

  resetSomeFormBookingCargo() {
    CargoController.form.vehicleNumber = "";
    CargoController.form.cargoNumber = "";
  }
  switchStep(index: number) {
    if (this.progress > index) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Yakin Kembali ke Halaman Sebelumnya ?",
          image: "leaving-page-illustration",
          message:
            "Anda akan mengulang proses yang sebelumnya apabila anda kembali",
          textCancel: "Ya",
          textSuccess: "Tidak",
          onClose: () => {
            MainAppController.closeMessageModal();
            CargoController.setProgressValue(index);
            this.resetSomeFormBookingCargo();
          },
          onSubmit: () => {
            MainAppController.closeMessageModal();
          }
        })
      );
    }
  }

  get progress(): number {
    return CargoController.progressValue;
  }
}
